import React, { useState, useContext } from 'react';
import { AnimatePresence } from 'framer-motion';
import ReactDOM from 'react-dom';
import { PaymentModalStyle, FormfieldStyle } from './style';
import CancelIcon from 'assets/icons/cancel_icon.svg';
import PlusIcon from 'assets/icons/plus.svg';
import MinusIcon from 'assets/icons/minus.svg';
import { UserContext } from 'state/contexts/UserContext';
import Spacer from 'utils/spacer';
import Textfield from 'components/Forms/TextInput';
import SelectInput from 'components/Forms/SelectInput';
import { SolidButton } from 'components/Button';
import { showErrorMessage, showSuccessMessage } from 'utils/toast';
import NaijaStates from 'naija-state-local-government';
import { PaystackConsumer } from 'react-paystack';
import supabase from 'network/supabaseClient';
import SoundcardImage from 'assets/images/soundcard.webp';
import formatter from 'utils/formatter';

const PaymentModal = () => {
  // Modal controls
  const { paymentModalDispatch } = useContext(UserContext);

  const closeModal = () => {
    paymentModalDispatch({ type: 'CLOSE_MODAL' });
    document.body.style.overflow = 'unset';
  };

  // Field states
  const onSelectLGA = (lga) => {
    setLga(lga);
  };

  const onSelectState = (state) => {
    setState(state);
    if (state === 'Lagos') {
      setDeliveryAmount(400000);
    } else {
      setDeliveryAmount(700000);
    }
  };

  const [fullname, setFullname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [lga, setLga] = useState('');
  const [state, setState] = useState('');

  const [deliveryAmount, setDeliveryAmount] = useState(0);
  const amount = 11000000;

  const [quantity, setQuantity] = useState(1);

  const addQuantity = () => {
    if (quantity !== 11 && quantity < 10) setQuantity(quantity + 1);
  };

  const removeQuantity = () => {
    if (quantity !== 1 && quantity > 1) setQuantity(quantity - 1);
  };

  // Paystack functions
  const publicKey = process.env.REACT_APP_PAYSTACK_API_KEY;

  const handleSuccess = (reference) => {
    showSuccessMessage({ message: 'Payment successful' });
    submitData();
  };

  const handleClose = () => {};

  const componentProps = {
    email,
    amount: amount * quantity,
    metadata: {
      fullname,
      phoneNumber
    },
    publicKey,
    text: 'Pay now',
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose
  };

  const validateData = () => {
    if (fullname === '') {
      showErrorMessage({ message: 'Name cannot be empty' });
      return false;
    }

    if (phoneNumber === '') {
      showErrorMessage({ message: 'Number cannot be empty' });
      return false;
    }

    if (email === '') {
      showErrorMessage({ message: 'Email cannot be empty' });
      return false;
    }

    if (deliveryAddress === '') {
      showErrorMessage({ message: 'Address cannot be empty' });
      return false;
    }

    if (state === '') {
      showErrorMessage({ message: 'Select a state' });
      return false;
    }

    if (lga === '') {
      showErrorMessage({ message: 'Select a LGA' });
      return false;
    }

    return true;
  };

  const submitData = async () => {
    const { data, error } = await supabase.from('Pre-order users').insert([
      {
        full_name: fullname,
        address: deliveryAddress,
        LGA: lga,
        state,
        hasPaid: true,
        phone: phoneNumber,
        email,
        quantity
      }
    ]);

    if (error) {
      showSuccessMessage({ message: error.details });
    }

    if (data) {
      showSuccessMessage({ message: 'Order successfully placed.' });
      resetData();
      closeModal();
    }
  };

  const resetData = () => {
    setFullname('');
    setDeliveryAddress('');
    setLga('');
    setState('');
    setPhoneNumber('');
    setEmail('');
  };

  return ReactDOM.createPortal(
    <AnimatePresence>
      <PaymentModalStyle>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-close" onClick={() => closeModal()}>
            <img src={CancelIcon} />
          </div>

          <div className="inner-content">
            <div className="modal-inner-content">
              <div className="text-container-1">
                <h3>Order Soundcard</h3>

                <Spacer axis="vertical" size={12} />
                <h6>
                  Fill in details, make sure they&apos;re correct as they would
                  be used to deliver your item to you.
                </h6>
              </div>
              <div className="form-container">
                <FormfieldStyle>
                  <h6 className="label">
                    Full Name <span className="star">*</span>
                  </h6>
                  <Textfield
                    label="John Doe"
                    type="name"
                    value={fullname}
                    onChange={setFullname}
                  />
                </FormfieldStyle>

                <Spacer axis="vertical" size={30} />

                <FormfieldStyle>
                  <h6 className="label">
                    Phone Number <span className="star">*</span>
                  </h6>
                  <Textfield
                    label="+000 000 000 0000"
                    type="phone"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                  />
                </FormfieldStyle>

                <Spacer axis="vertical" size={30} />

                <FormfieldStyle>
                  <h6 className="label">
                    Email <span className="star">*</span>
                  </h6>
                  <Textfield
                    label="example@mail.com"
                    type="email"
                    value={email}
                    onChange={setEmail}
                  />
                </FormfieldStyle>

                <Spacer axis="vertical" size={30} />

                <FormfieldStyle>
                  <h6 className="label">
                    Delivery Address <span className="star">*</span>
                  </h6>
                  <Textfield
                    label="Street name / Building / Apartment No. / Floor"
                    value={deliveryAddress}
                    type="address"
                    onChange={setDeliveryAddress}
                  />
                </FormfieldStyle>

                <Spacer axis="vertical" size={30} />

                <FormfieldStyle>
                  <h6 className="label">
                    State/Region <span className="star">*</span>
                  </h6>
                  <SelectInput
                    header="Choose state"
                    items={NaijaStates.states()}
                    onSelect={onSelectState}
                  />
                </FormfieldStyle>

                <Spacer axis="vertical" size={30} />

                <FormfieldStyle>
                  <h6 className="label">
                    LGA/City <span className="star">*</span>
                  </h6>
                  <SelectInput
                    header="Choose LGA"
                    items={state !== '' ? NaijaStates.lgas(state).lgas : []}
                    onSelect={onSelectLGA}
                  />
                </FormfieldStyle>

                <Spacer axis="vertical" size={10} />
              </div>
            </div>

            <div className="modal-inner-content-2">
              <h3 className="summary">Order summary</h3>
              <Spacer axis="vertical" size={20} />
              <div className="flex-container">
                <h5 className="order">Order amount</h5>
                <h5 className="order-charge">
                  {formatter.format(amount / 100)}
                </h5>
              </div>{' '}
              <Spacer axis="vertical" size={15} />
              <hr />
              <Spacer axis="vertical" size={15} />
              <div className="flex-container">
                <h5 className="order">Delivery charges</h5>
                <h5 className="order-charge">
                  {formatter.format(deliveryAmount / 100)}
                </h5>
              </div>
              <Spacer axis="vertical" size={30} />
              <div className="flex-container-2 qtn">
                <div className="image-box">
                  <img src={SoundcardImage} />
                </div>
                <Spacer axis="horizontal" size={10} />
                <div>
                  <h5 className="name">Sokay CS-22</h5>
                  <Spacer axis="vertical" size={5} />
                  <h6 className="sub">
                    24-Bit, 192hz, 2 Channels, Metal.
                    <br /> Color - Black.
                  </h6>
                  <Spacer axis="vertical" size={5} />
                  <div className="qty-container">
                    <button className="qty-button" onClick={addQuantity}>
                      <img src={PlusIcon} />
                    </button>
                    <Spacer axis="horizontal" size={15} />
                    <h5> {quantity} </h5>
                    <Spacer axis="horizontal" size={15} />
                    <button className="qty-button" onClick={removeQuantity}>
                      <img src={MinusIcon} />
                    </button>
                  </div>
                </div>
              </div>
              <Spacer axis="vertical" size={20} />
              <hr />
              <Spacer axis="vertical" size={20} />
              <div className="flex-container">
                <h5 className="amounts">Total amount</h5>
                {/* <h5> {formatter.format((amount + deliveryAmount) / 100)}</h5> */}
                <h5 className="amounts">
                  {' '}
                  {formatter.format((amount / 100) * quantity)}
                </h5>
              </div>
              <Spacer axis="vertical" size={16} />
              <PaystackConsumer {...componentProps}>
                {({ initializePayment }) => (
                  <SolidButton
                    label="Place order"
                    onClick={() => {
                      if (validateData()) {
                        initializePayment(handleSuccess, handleClose);
                      }
                    }}
                  />
                )}
              </PaystackConsumer>
            </div>
          </div>
        </div>
      </PaymentModalStyle>
    </AnimatePresence>,
    document.getElementById('root')
  );
};

export default PaymentModal;
