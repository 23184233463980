import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { primaryFont, typeScale, backgroundColor } from 'theme';

const GlobalStyles = createGlobalStyle`
    html {

        background: white;
    }
    *, *:before, *:after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /* -webkit-transition: all 0.30s ease-in-out;
        -moz-transition: all 0.30s ease-in-out;
        -ms-transition: all 0.30s ease-in-out;
        -o-transition: all 0.30s ease-in-out; */
    }
    a {
        text-decoration: none;
      }
    h1{
        font-size: ${typeScale.header1};
    }
    body {
        background: ${backgroundColor[100]};
        transition: all 0.05s linear;
        font-family: ${primaryFont};
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
`;

const StyledContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast-body {
    font-family: ${primaryFont};
  }
  .Toastify__progress-bar {
  }
`;

export { GlobalStyles, StyledContainer };
