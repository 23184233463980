import styled from 'styled-components';
import { textColor, secondaryFont } from 'theme';

const TextfieldStyle = styled.input`
  height: 3.1rem;
  width: 100%;
  color: #777e90;
  background-color: transparent;
  border: 1.2px solid rgba(71, 71, 71, 0.17);
  border-radius: 7px;

  font-family: ${secondaryFont};

  padding-right: 5px;
  outline: 0;
  text-indent: 16px;

  font-size: 13.811px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.73px;

  &:focus {
    border: 1.5px solid rgba(71, 71, 71, 0.17);
  }

  ::placeholder {
    opacity: 0.6;
  }

  @media (max-width: 600px) {
    font-size: 0.8rem !important;
    height: 2.6rem;
    text-indent: 10px;
  }
`;

export { TextfieldStyle };
