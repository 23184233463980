import React from 'react';
import { OutlineButtonStyle } from './style';

const OutlineButton = ({ label, onClick, disabled }) => {
  return (
    <OutlineButtonStyle
      disabled={disabled}
      onClick={!disabled ? onClick : () => {}}
    >
      {label || 'label'}
    </OutlineButtonStyle>
  );
};

export default OutlineButton;
