import React from 'react';
import { GlobalStyles, StyledContainer } from './Global';
import Home from '../pages/home';
import UserContextProvider from 'state/contexts/UserContext';
UserContextProvider;
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <>
      <UserContextProvider>
        <GlobalStyles />
        <StyledContainer />
        <Home />
      </UserContextProvider>
    </>
  );
};

export default App;
