import styled from 'styled-components';
import {
  textColor,
  primaryColor,
  backgroundColor,
  primaryFont,
  secondaryFont
} from 'theme';

const PaymentModalStyle = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  //   pointer-events: none;
  z-index: 999;

  & .modal-content {
    width: 70%;
    height: 90%;
    background-color: ${backgroundColor[100]};
    border-radius: 29px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-in-out;

    & .modal-close {
      margin-top: 20px;
      margin-right: 20px;
      float: right;

      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.8rem;
      width: 2.8rem;
      background-color: #858585;
      border: none;
      border-radius: 100%;

      cursor: pointer;

      img {
        width: 18px;
        height: 18px;
      }
    }

    & .inner-content {
      width: 100%;
      display: flex;
      height: 100%;

      @media (max-width: 700px) {
        display: block;
        overflow-y: auto;
      }

      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-track {
        background: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background: rgba(71, 71, 71, 0.17);
        border-radius: 5px;
      }
    }

    & .modal-inner-content-2 {
      margin-top: 40px;
      padding: 0px 20px 20px 10px;
      width: 43%;

      & .summary {
        color: #23262f;
        font-family: ${primaryFont};
        font-size: 29.596px;
        font-style: normal;
        font-weight: 500;
        line-height: 49.271px;
        letter-spacing: -0.395px;
      }

      hr {
        border: 0;
        height: 1.3px;
        background: rgba(71, 71, 71, 0.17);
      }

      & .order {
        color: #777e90;
        font-family: ${primaryFont};
        font-size: 19.73px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
      }

      & .order-charge {
        color: #23262f;
        font-family: ${primaryFont};
        font-size: 19.73px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
      }

      & .name {
        color: #23262f;
        font-family: ${primaryFont};
        font-size: 29.596px;
        font-style: normal;
        font-weight: 500;
        line-height: 49.271px;
      }

      & .image-box {
        img {
          height: 55px;
        }
      }

      @media (max-width: 700px) {
        width: 100%;
        padding: 10px 30px 170px 30px;
        overflow-y: unset;
      }
    }

    & .qtn {
      align-items: center;
    }

    & .amounts {
      color: #23262f;
      font-family: ${primaryFont};
      font-size: 19.73px;
      font-style: normal;
      font-weight: 500;
      line-height: 49.271px;
    }

    & .qty-container {
      display: flex;
      align-items: center;

      h5 {
        font-size: 1rem;
        @media (max-width: 700px) {
          font-size: 0.9rem;
        }
      }

      & .qty-button {
        background-color: transparent;
        outline: none;
        cursor: pointer;
        text-align: center;
        padding-top: 2px;
        width: 37px;
        height: 49px;
        border-radius: 237.6px;
        border: 0.59px solid;
        border-color: #777e90;

        img {
          height: 10px;
        }

        @media (max-width: 700px) {
          height: 25px;
          width: 35px;
        }
      }
    }

    & .modal-inner-content {
      width: 55%;
      height: 90%;
      overflow-y: auto;
      padding: 20px 0px 20px 50px;

      & .text-container-1 {
        width: 90%;
        margin-bottom: 10px;
        h3 {
          font-family: ${primaryFont};
          color: #23262f;
          font-size: 29.596px;
          font-style: normal;
          font-weight: 500;
          line-height: 49.271px;
        }

        h6 {
          font-family: ${secondaryFont};
          color: #777e90;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21.116px;
        }
      }

      & .form-container {
        width: 89%;
        @media (max-width: 700px) {
          width: 100%;
          padding-top: 10px;
        }
      }
      @media (max-width: 700px) {
        width: 100%;
        height: fit-content;
        padding: 0px 30px 10px 30px;
        overflow-y: unset;
      }

      ::-webkit-scrollbar {
        width: 1px;
      }

      ::-webkit-scrollbar-track {
        background: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background: transparent;
      }
    }

    & .text-container {
      padding-top: 50px;
      padding-left: 50px;
      width: 50%;

      h3 {
        font-size: 1.4rem;
        color: ${textColor[200]};
      }

      h6 {
        font-size: 0.9rem;
        line-height: 20px;
        color: rgba(83, 78, 110, 0.8);
      }
      @media (max-width: 700px) {
        width: 80%;
        padding-top: 30px;
        padding-left: 30px;
        padding-bottom: 10px;
      }
    }

    & .flex-container {
      display: flex;
      justify-content: space-between;
    }
    & .flex-container-2 {
      display: flex;

      & .sub {
        font-family: ${primaryFont};
        font-size: 13.811px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.116px;
      }
    }

    @media (max-width: 700px) {
      width: 90%;
    }
  }
`;

const FormfieldStyle = styled.div`
  & .label {
    color: #23262f;
    font-size: 13.811px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.73px;
    margin-bottom: 8px;
    font-family: ${secondaryFont};
  }

  & .star {
    color: #eb5757;
  }
`;

export { PaymentModalStyle, FormfieldStyle };
