import React, { useState } from 'react';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownListContainer,
  DropDownList,
  ListItem
} from './style';
import ExpandIcon from 'assets/icons/expand_icon.svg';

const SelectInput = ({ items = [], header, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    onSelect(value);
  };

  const listItems = items.map((item, id) => (
    <ListItem key={id} onClick={onOptionClicked(item)}>
      {item}
    </ListItem>
  ));

  const handleDivBlur = (event) => {
    if (isOpen === true) setIsOpen(false);
  };

  return (
    <div>
      <DropDownContainer tabIndex={0} onBlur={handleDivBlur}>
        <DropDownHeader onClick={toggling}>
          {selectedOption || header} <img src={ExpandIcon} />
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {listItems ?? (
                <>
                  <ListItem onClick={() => setIsOpen(false)}>Hello</ListItem>
                </>
              )}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </div>
  );
};

export default SelectInput;
