export const primaryFont = 'Euclid Circular A';
export const secondaryFont = 'Poppins';
// 'Poppins', Helvetica, sans-serif

export const typeScale = {
  header1: '3rem',
  header2: '2.6rem',
  header3: '1.5rem',
  header4: '1.8rem',
  header5: '1.4rem',
  bodyText1: '1.6rem',
  bodyText2: '1.4rem',
  bodyText3: '1.2rem',
  bodyText4: '1.0rem'
};
