import React from 'react';

import { TextfieldStyle } from './style';

const Textfield = ({ type = 'text', label, value, onChange, error }) => (
  <TextfieldStyle
    type={type}
    value={value}
    error={error}
    name={type}
    placeholder={label}
    onChange={(e) => onChange && onChange(e.target.value)}
  />
);

export default Textfield;
