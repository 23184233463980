import styled from 'styled-components';
import { textColor, primaryColor, primaryFont } from 'theme';

const SolidButtonStyle = styled.button`
  background-color: ${primaryColor[100]};
  color: ${textColor[100]};
  cursor: pointer;
  height: 3rem;
  // width: 11rem;
  width: 100%;
  border-radius: 5px;
  background: var(
    --gradient-blu,
    linear-gradient(0deg, #2762fb 40.62%, #018dfe 70.83%)
  );
  font-size: 0.8rem;
  font-family: ${primaryFont};
  font-weight: bold;
  outline: none;
  border: none;
  transition: 0.3s ease-out;
  padding-left: 24px;
  padding-right: 24px;

  &:hover {
    // background-color: ${primaryColor[200]};
  }

  @media (max-width: 600px) {
    font-size: 0.8rem !important;
    height: 2.5rem;
  }
`;

const OutlineButtonStyle = styled(SolidButtonStyle)`
  background: transparent;
  border: 1.35px solid ${primaryColor[300]};
  color: ${textColor[100]};
  // width: 11rem;
  width: 100%;

  &:hover {
    // border-color: ${primaryColor[200]};
    // background-color: ${primaryColor[100]};
    color: ${textColor[100]};
  }

  @media (max-width: 600px) {
    font-size: 0.8rem !important;
    height: 2.5rem;
  }
`;

export { SolidButtonStyle, OutlineButtonStyle };
