import React from 'react';
import { SolidButtonStyle } from './style';

const Button = ({ label, onClick, disabled }) => {
  return (
    <SolidButtonStyle
      disabled={disabled}
      onClick={!disabled ? onClick : () => {}}
    >
      {label || 'label'}
    </SolidButtonStyle>
  );
};

export default Button;
