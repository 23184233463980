import styled from 'styled-components';
import { secondaryFont } from 'theme';

const DropDownContainer = styled('div')`
  width: 100%;
`;

const DropDownHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.1rem;
  color: #777e90;
  background-color: transparent;

  border: 1.2px solid rgba(71, 71, 71, 0.17);
  border-radius: 7px;

  padding: 0 16px;

  font-family: ${secondaryFont};
  font-size: 13.811px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.73px;

  outline: 0;

  cursor: pointer;

  @media (max-width: 600px) {
    font-size: 0.8rem !important;
    height: 2.6rem;
    padding: 0 10px !important;
  }
`;

const DropDownListContainer = styled('div')`
  position: relative;
`;

const DropDownList = styled('ul')`
  position: absolute;
  height: 160px;
  width: 90%;
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 1.4px solid rgba(71, 71, 71, 0.17);
  margin-top: 10px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgba(83, 78, 110, 0.6);
  font-family: ${secondaryFont};
  font-size: 13.811px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.73px;
  overflow-y: auto;

  &:first-child {
    padding-top: 16px;
  }

  @media (max-width: 600px) {
    font-size: 0.8rem !important;
    padding-left: 10px !important;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(71, 71, 71, 0.17);
    border-radius: 5px;
  }
`;

const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 16px;
  cursor: pointer;
`;

export {
  DropDownContainer,
  DropDownHeader,
  DropDownListContainer,
  DropDownList,
  ListItem
};
