import React, { useReducer, createContext, useEffect } from 'react';
import { LightboxModalReducer } from 'state/reducers/LightboxModalReducer';
import { PaymentModalReducer } from 'state/reducers/PaymentModalReducer';

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [showPaymentModal, paymentModalDispatch] = useReducer(
    PaymentModalReducer,
    false
  );

  const [showLightboxModal, lightboxModalDispatch] = useReducer(
    LightboxModalReducer,
    false
  );

  return (
    <UserContext.Provider
      value={{
        showPaymentModal,
        paymentModalDispatch,
        showLightboxModal,
        lightboxModalDispatch
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
