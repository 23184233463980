import styled from 'styled-components';
import { textColor, primaryColor, backgroundColor } from 'theme';

const HeaderStyle = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  transition: 0.3s ease-out;
  background: transparent;
  padding-top: 2.7rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 15px;

  h1 {
    color: ${textColor[100]};
    font-size: 1.3rem;
    font-weight: 400;
    @media (max-width: 700px) {
      font-size: 1.2rem;
    }
  }

  & .social-container {
    display: inline-flex;
    h4 {
      font-size: 0.8rem;
      font-weight: bold;
      color: ${textColor[100]};
    }

    img {
      height: 21px;
    }
    @media (max-width: 700px) {
      display: none;
    }
  }

  & .logo {
    margin-bottom: 5px;
    height: 45px;
  }

  @media (max-width: 700px) {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
`;

export default HeaderStyle;
