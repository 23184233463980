import { toast } from 'react-toastify';
import 'index.css';

const showSuccessMessage = ({ message }) => {
  toast.success(message ?? 'Success', {
    position: toast.POSITION.TOP_RIGHT,
    className: 'toast-message',
    closeButton: false
  });
};

const showErrorMessage = ({ message }) => {
  toast.error(message ?? 'Error', {
    position: toast.POSITION.TOP_RIGHT,
    className: 'toast-message',
    closeButton: false
  });
};

export { showSuccessMessage, showErrorMessage };
