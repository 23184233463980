import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import SoundcardImage1 from 'assets/images/soundcard1.webp';
import SoundcardImage2 from 'assets/images/soundcard2.webp';
import CircleButton from 'assets/icons/circle_button.svg';
import PlayButton from 'assets/icons/play_button.svg';
import Scarlett from 'assets/images/card.webp';
import SokaySpecs from 'assets/images/card_specs.webp';
import SokaySpecifications from 'assets/icons/specs.svg';
import Facebook from 'assets/icons/socials/facebook.svg';
import Instagram from 'assets/icons/socials/instagram.svg';
import Twitter from 'assets/icons/socials/twitter.svg';
import SokaySpecificationsMobile from 'assets/icons/specs_mobile.svg';
import ArrowIcon from 'assets/icons/arrow_icon.svg';
import HomeStyle from './style';
import { UserContext } from 'state/contexts/UserContext';
import PaymentModal from 'components/PaymentModal';
import { OutlineButton, SolidButton } from 'components/Button';
import Spacer from 'utils/spacer';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const images = [
  'https://res.cloudinary.com/emmanueljoshua/image/upload/v1667330783/Sokay%20Promo/1_sjvero.png',
  'https://res.cloudinary.com/emmanueljoshua/image/upload/v1667330814/Sokay%20Promo/2_ikdmje.png',
  'https://res.cloudinary.com/emmanueljoshua/image/upload/v1667330828/Sokay%20Promo/3_yltvhw.png',
  'https://res.cloudinary.com/emmanueljoshua/image/upload/v1667330860/Sokay%20Promo/5_rdoimd.png',
  'https://res.cloudinary.com/emmanueljoshua/image/upload/v1667330875/Sokay%20Promo/6_afisvd.png',
  'https://res.cloudinary.com/emmanueljoshua/image/upload/v1667330890/Sokay%20Promo/7_mrl5hl.png',
  'https://res.cloudinary.com/emmanueljoshua/image/upload/v1667330923/Sokay%20Promo/9_syccgd.png'
];

const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
`;

const Home = () => {
  const { showPaymentModal, paymentModalDispatch } = useContext(UserContext);
  const { showLightboxModal, lightboxModalDispatch } = useContext(UserContext);
  const [index, setIndex] = useState(0);
  const ref = useRef(null);
  const ref2 = useRef(null);

  const scrollToSpecs = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToInfo = () => {
    ref2.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const openModal = () => {
    paymentModalDispatch({
      type: 'OPEN_MODAL'
    });
    document.body.style.overflow = 'hidden';
  };

  const openLightbox = () => {
    lightboxModalDispatch({
      type: 'OPEN_MODAL'
    });
    document.body.style.overflow = 'hidden';
  };

  const closeLightbox = () => {
    lightboxModalDispatch({
      type: 'CLOSE_MODAL'
    });
    document.body.style.overflow = 'unset';
  };

  return (
    <>
      {showLightboxModal && (
        <Lightbox
          enableZoom={false}
          mainSrc={images[index]}
          nextSrc={images[(index + 1) % images.length]}
          prevSrc={images[(index - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => {
            if (index !== 0)
              setIndex((index + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            if (index + 1 < images.length) setIndex(index + 1) % images.length;
          }}
        />
      )}
      {showPaymentModal && <PaymentModal />}
      <Header />
      <Wrapper>
        <HomeStyle>
          <div className="header-container">
            <div className="hero-content">
              <div className="frame">
                <div className="div">
                  <div className="the-essential-audio-wrapper">
                    <p className="the-essential-audio">
                      <span className="span">
                        The essential
                        <br />
                        audio interface.
                        <br />
                      </span>
                      <span className="text-wrapper-2">
                        Just Plug &amp; Play.
                      </span>
                    </p>
                  </div>
                  <p className="p">
                    Unlock the World of Sound, Effortlessly. Your Ultimate
                    Essential Audio Interface: Just Plug and Play.
                  </p>
                </div>
              </div>

              <div className="buttons">
                <SolidButton label="Out of Stock" onClick={() => {}} />
                <OutlineButton
                  label="See full spec"
                  onClick={() => scrollToSpecs()}
                />
              </div>
              <img src={CircleButton} onClick={() => scrollToInfo()} />
            </div>

            <div className="">
              <img className="header-image" src={Scarlett} />
            </div>
          </div>
          <hr />
          <div ref={ref2} className="laptop-bg">
            <Spacer axis="vertical" size={50} />

            <div className="card-content">
              <div className="text-wrapper">SOKAY CS-22</div>
              <div className="text-block">
                <div className="div">Simple, powerful, easy-to-use</div>
                <p className="p">
                  CS-22 is a high-performance and spectacularly capable stage
                  and studio centrepiece for PC, Mac , Android and ios which
                  inspires producers, engineers, artists, instrumentalist,
                  podcasters and live-streaming programmers to create their best
                  work.
                </p>
              </div>
            </div>

            <Spacer axis="vertical" size={85} />
            <div className="card-values">
              <div className="values">
                <div className="frame">
                  <div className="div-wrapper">
                    <div className="text-wrapper">01</div>
                  </div>
                  <div className="div">
                    <div className="text-wrapper-2">
                      Versatile Compatibility
                    </div>
                    <p className="p">
                      Adaptable hub for producers, artists, and podcasters on
                      PC, Mac, Android, and iOS.
                    </p>
                  </div>
                </div>
                <div className="frame">
                  <div className="frame-2">
                    <div className="text-wrapper">02</div>
                  </div>
                  <div className="div">
                    <div className="text-wrapper-3">
                      Inspirational Creativity
                    </div>
                    <p className="p">
                      Empowers engineers, instrumentalists, and live-streamers,
                      igniting creativity for exceptional work.
                    </p>
                  </div>
                </div>
                <div className="frame">
                  <div className="frame-3">
                    <div className="text-wrapper">03</div>
                  </div>
                  <div className="div">
                    <div className="text-wrapper-3">High-Performance Hub</div>
                    <p className="text-wrapper-4">
                      Excels as a high-performance tool, enhancing projects for
                      musicians, podcasters, and artists with diverse features.
                    </p>
                  </div>
                </div>
              </div>
              <div className="values-image">
                <img src={PlayButton} />
              </div>
            </div>

            <Spacer axis="vertical" size={72} />
          </div>

          <div className="device-container">
            <div className="left-container">
              <div className="frame">
                <div className="text-block">
                  <div className="text-wrapper">
                    Powerful Studio Audio Interface
                  </div>
                  <p className="div">
                    CS-22: Record vocals, guitar, synths, and more
                    simultaneously. Unleash your creativity with analog purity
                    and clarity. Plug and play for maximum potential.
                  </p>
                </div>
                <div className="buttons">
                  <div className="button" onClick={() => {}}>
                    <h3 className="label">Out of Stock</h3>
                  </div>
                </div>
              </div>
              <img src={SoundcardImage1} />
            </div>
            <div className="right-container">
              <div className="frame">
                <div className="text-block">
                  <div className="text-wrapper">
                    Two High-Performance Midas Preamps
                  </div>
                  <p className="div">
                    Two Midas mic preamps, high-headroom, low-noise,
                    low-distortion. Analog Hi-z, lowcut, impedance switching,
                    and relay control for precise vocal and acoustic capture.
                  </p>
                </div>
                <div className="buttons">
                  <div className="button" onClick={() => {}}>
                    <h3 className="label">Out of Stock</h3>
                  </div>
                </div>
              </div>
              <img src={SoundcardImage2} />
            </div>
          </div>
          <div className="laptop-bg">
            <div ref={ref} className="main-specs-container">
              <img className="main1" src={SokaySpecs} />
              <Spacer axis="vertical" size={120} />
              <img className="main2" src={SokaySpecifications} />
              <img className="main2_mobile" src={SokaySpecificationsMobile} />
            </div>
            <div className="footer">
              <div>
                <h3>ARE YOU A RESELLER?</h3>
              </div>

              <a
                href="mailto:sokaytechnologies@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <div className="single-input-field">
                  <div className="enter-your-email">Email us now</div>
                  <div className="button-secondary">
                    <img className="icons-arrow-right" src={ArrowIcon} />
                  </div>
                </div>
              </a>
              <div className="social-container">
                <a
                  href="https://www.facebook.com/sokaytechstores"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Facebook} />
                </a>
                <Spacer axis="horizontal" size={22} />
                <a
                  href="https://www.twitter.com/sokaytechstores"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Twitter} />
                </a>
                <Spacer axis="horizontal" size={22} />
                <a
                  href="https://www.instagram.com/sokaytechstores"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Instagram} />
                </a>
              </div>
              {/* <Spacer axis="vertical" size={80} /> */}
              <p className="text-wrapper">
                © 2023 Sokay Tech Stores Inc. All rights reserved
              </p>
            </div>
          </div>
        </HomeStyle>
      </Wrapper>
    </>
  );
};

export default Home;
