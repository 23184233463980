import React, { useState, useEffect } from 'react';
import HeaderStyle from './style';
import Logo from 'assets/icons/logo.svg';
import Facebook from 'assets/icons/socials/facebook.svg';
import Instagram from 'assets/icons/socials/instagram.svg';
import Twitter from 'assets/icons/socials/twitter.svg';
import Spacer from 'utils/spacer';
import './blur.css';

const Header = () => {
  const [top, setTop] = useState(true);

  useEffect(() => {
    const scrollHandler = () => {
      setTop(window.scrollY <= 20);
    };
    window.addEventListener('scroll', scrollHandler);

    // Explicit call so that the navbar gets blurred when component mounts
    scrollHandler();

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <HeaderStyle className={`${!top && 'navbar-blur'}`}>
      <img className="logo" src={Logo} />
      <div className="social-container">
        <a
          href="https://www.facebook.com/sokaytechstores"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Facebook} />
        </a>
        <Spacer axis="horizontal" size={22} />
        <a
          href="https://www.twitter.com/sokaytechstores"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Twitter} />
        </a>
        <Spacer axis="horizontal" size={22} />
        <a
          href="https://www.instagram.com/sokaytechstores"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Instagram} />
        </a>
      </div>
    </HeaderStyle>
  );
};

export default Header;
