import styled from 'styled-components';
import { textColor, backgroundColor, primaryFont, secondaryFont } from 'theme';

const HomeStyle = styled.div`
  height: 100%;
  width: 100vw;
  background: url(/img/bg_image.webp), lightgray;
  // background-position: right center, center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #060707;

  & .header-container {
    height: 100vh;
    align-content: center;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 5rem;
    padding-right: 5rem;

    .hero-content {
      align-items: flex-start;

      display: inline-flex;
      flex-direction: column;
      gap: 49px;
      position: relative;
      justify-content: flex-end;
    }

    .hero-content .frame {
      align-items: flex-start;
      display: inline-flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 32px;
      position: relative;
    }

    .hero-content .div {
      align-items: flex-start;
      display: inline-flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 20px;
      position: relative;
    }

    .hero-content .the-essential-audio-wrapper {
      align-items: flex-start;
      display: inline-flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 12px;
      position: relative;
    }

    .hero-content .the-essential-audio {
      color: transparent;
      font-family: ${primaryFont};
      font-size: 64px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 64px;
      margin-top: -1px;
      position: relative;
      width: 545px;

      @media (max-width: 1350px) {
        width: 350px;
        font-size: 35px;
        line-height: 46px;
      }

      @media (max-width: 700px) {
        width: 100%;
        font-size: 40px;
        line-height: 56px;
      }
    }

    .hero-content .span {
      color: #fbfcfc;
    }

    .hero-content .text-wrapper-2 {
      color: #2b78ff;
    }

    .hero-content .p {
      color: #8a93ab;
      font-family: ${secondaryFont};
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 24px;
      position: relative;
      width: 448px;

      @media (max-width: 700px) {
        width: 100%;
      }
    }

    .hero-content .buttons {
      align-items: center;
      width: 70%;
      display: inline-flex;
      flex: 0 0 auto;
      gap: 16px;

      justify-content: center;
      position: relative;

      @media (max-width: 700px) {
        width: 100%;
        display: block;

        button {
          margin-bottom: 16px;
        }
      }
    }

    .hero-content img {
      margin-top: 40px;
      cursor: pointer;

      @media (max-width: 700px) {
        display: none;
      }
    }

    & .header-image {
      height: 40rem;
      @media (max-width: 1300px) {
        height: 35rem;
      }

      @media (max-width: 700px) {
        margin-top: 20px;
        max-width: 100%;
        height: auto;
      }
    }

    @media (max-width: 700px) {
      height: 100%;
      padding-top: 10rem;
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }

  hr {
    border: 0;
    height: 1.5px;
    margin-left: 5rem;
    margin-right: 5rem;
    background-color: #23262f;

    @media (max-width: 700px) {
      display: none;
    }
  }

  & .card-content {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-left: 8rem;
    margin-right: 8rem;

    .text-wrapper {
      color: #b1b5c3;
      font-family: ${secondaryFont};
      font-size: 14.3px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 14.3px;
      margin-top: -0.89px;
      position: relative;
      white-space: nowrap;
      width: fit-content;

      @media (max-width: 700px) {
        display: none;
      }
    }

    .text-block {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 17.84px;
      position: relative;
      width: 488.74px;

      @media (max-width: 700px) {
        width: 100%;
      }
    }

    .div {
      color: ${textColor[400]};
      font-family: ${primaryFont};
      font-size: 42.8px;
      font-weight: 700;
      letter-spacing: -0.86px;
      line-height: 49.9px;
      margin-top: -0.89px;
      position: relative;
      width: 404.01px;

      @media (max-width: 700px) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .p {
      color: ${textColor[500]};
      font-family: ${secondaryFont};
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 21.4px;
      position: relative;

      @media (max-width: 700px) {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (max-width: 700px) {
      margin-left: 2rem;
      margin-right: 2rem;
      display: block;
      text-align: center;
    }
  }

  & .card-values {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-left: 6rem;
    margin-right: 6rem;

    & .values {
      align-items: flex-start;
      display: inline-flex;
      flex-direction: column;
      gap: 42.81px;
      height: 579.7px;
      position: relative;
      justify-content: center;

      .frame {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 21.4px;
        position: relative;
      }

      .div-wrapper {
        align-items: flex-start;
        background-color: #9757d7;
        border-radius: 89.19px;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8.92px;
        padding: 1.78px 10.7px;
        position: relative;
      }

      .text-wrapper {
        color: ${textColor[400]};
        font-family: ${secondaryFont};
        font-size: 12.5px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17.8px;
        margin-top: -0.89px;
        white-space: nowrap;
        width: fit-content;
      }

      .div {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 14.27px;
        position: relative;
      }

      .text-wrapper-2 {
        color: ${textColor[400]};
        font-family: ${secondaryFont};
        font-size: 14.3px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21.4px;
        margin-top: -0.89px;
        position: relative;
        width: 233.67px;

        @media (max-width: 700px) {
          width: 90%;
        }
      }

      .p {
        color: ${textColor[500]};
        font-family: ${secondaryFont};
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 21.4px;
        position: relative;
        width: 387px;

        @media (max-width: 700px) {
          width: 90%;
        }
      }

      .frame-2 {
        align-items: flex-start;
        background-color: #ef466f;
        border-radius: 89.19px;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8.92px;
        padding: 1.78px 10.7px;
        position: relative;
      }

      .text-wrapper-3 {
        color: ${textColor[400]};
        font-family: ${secondaryFont};
        font-size: 14.3px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21.4px;
        margin-top: -0.89px;
        position: relative;
        width: 233.67px;

        @media (max-width: 700px) {
          width: 100%;
        }
      }

      .frame-3 {
        align-items: flex-start;
        background-color: #45b26a;
        border-radius: 89.19px;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8.92px;
        padding: 1.78px 10.7px;
        position: relative;
      }

      .text-wrapper-4 {
        color: ${textColor[500]};
        font-family: ${secondaryFont};
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 21.4px;
        position: relative;
        width: 385px;

        @media (max-width: 700px) {
          width: 90%;
        }
      }
    }

    .values-image {
      border-radius: 20px;
      display: inline-flex;
      background: url(/img/card1.webp);
      width: 488px;
      height: 578px;
      justify-content: center;
      align-items: center;

      img {
        width: 71px;
        height: 71px;
      }

      @media (max-width: 700px) {
        max-width: 90%;
        height: 30rem;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    @media (max-width: 700px) {
      flex-direction: column-reverse;
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  & .laptop-bg {
    background: url(/img/laptop_bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #060707;
    width: 100%;
    height: 100%;
  }

  & .device-container {
    display: flex;
    width: 100%;

    & .left-container {
      background-color: #2762fb;
      width: 50%;

      display: flex;
      flex-direction: column;
      align-content: space-around;
      align-items: flex-end;
      justify-content: center;

      padding-top: 58px;
      padding-right: 70px;

      & .frame {
        align-items: flex-start;
        display: inline-flex;
        flex-direction: column;
        gap: 23px;

        .text-block {
          align-items: flex-start;
          display: flex;
          flex: 0 0 auto;
          flex-direction: column;
          gap: 17.84px;
          width: 445px;

          @media (max-width: 700px) {
            margin-right: auto;
            margin-left: auto;
            width: 80%;
          }
        }

        .text-wrapper {
          color: #ffffff;
          font-family: ${primaryFont};
          font-size: 40px;
          font-weight: 700;
          letter-spacing: -0.86px;
          line-height: 49.9px;
          position: relative;
        }

        .div {
          color: #ffffff;
          font-family: ${secondaryFont};
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 21.4px;
          position: relative;
          width: 426px;

          @media (max-width: 700px) {
            width: 100%;
          }
        }

        .buttons {
          align-items: center;
          display: inline-flex;
          flex: 0 0 auto;
          gap: 16px;
          justify-content: center;
          position: relative;

          .button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 16px 24px;
            background-color: #ffffff;
            border-radius: 10px;
            box-sizing: border-box;
            cursor: pointer;

            .label {
              color: #141416;
              font-family: ${primaryFont};
              font-size: 16px;
              font-weight: 700;
              letter-spacing: 0;
              line-height: 16px;
              text-align: center;
            }
          }

          @media (max-width: 700px) {
            margin-right: 3rem;
          }
        }

        @media (max-width: 700px) {
          align-items: flex-end;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }

      @media (max-width: 700px) {
        width: 100%;
        padding-right: 0;
      }
    }

    & .right-container {
      background-color: #fcfcfd;
      width: 50%;

      display: flex;
      flex-direction: column;
      align-content: space-around;
      align-items: flex-start;
      justify-content: center;

      padding-top: 68px;
      padding-left: 70px;

      & .frame {
        align-items: flex-start;

        display: inline-flex;
        flex-direction: column;
        gap: 23px;

        .text-block {
          align-items: flex-start;
          display: flex;
          flex: 0 0 auto;
          flex-direction: column;
          gap: 17.84px;
          position: relative;
          width: 445px;

          @media (max-width: 700px) {
            margin-right: auto;
            margin-left: auto;
            width: 80%;
          }
        }

        .text-wrapper {
          color: #23262f;
          font-family: ${primaryFont};
          font-size: 40px;
          font-weight: 700;
          letter-spacing: -0.86px;
          line-height: 49.9px;
        }

        .div {
          color: #777e90;
          font-family: ${secondaryFont};
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 21.4px;
          position: relative;
          width: 426px;

          @media (max-width: 700px) {
            width: 100%;
          }
        }

        & .buttons {
          align-items: center;
          display: inline-flex;
          flex: 0 0 auto;
          gap: 16px;
          justify-content: center;
          position: relative;

          .button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 16px 24px;
            border-radius: 10px;
            border: 0.5px solid;
            border-color: #b5b5b5;
            box-sizing: border-box;
            cursor: pointer;

            .label {
              color: #141416;
              font-family: ${primaryFont};
              font-size: 16px;
              font-weight: 700;
              letter-spacing: 0;
              line-height: 16px;
              text-align: center;
            }
          }

          @media (max-width: 700px) {
            margin-right: 3rem;
          }
        }

        @media (max-width: 700px) {
          align-items: flex-end;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }

      @media (max-width: 700px) {
        width: 100%;
        padding-left: 0px;
      }
    }

    @media (max-width: 700px) {
      display: block;
    }
  }

  & .main-specs-container {
    padding-top: 150px;
    padding-bottom: 120px;
    text-align: center;

    & .main1 {
      max-width: 100%;
      height: auto;
    }

    & .main2 {
      max-width: 100%;
      height: auto;

      @media (max-width: 700px) {
        display: none;
      }
    }

    & .main2_mobile {
      max-width: 100%;
      height: auto;
      display: none;

      @media (max-width: 700px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  & .footer {
    width: 100%;
    display: block;
    align-items: center;
    z-index: 1;
    height: fit-content;
    padding-left: 1.2rem;
    padding-bottom: 2rem;
    padding-top: 116px;
    background: rgba(0, 0, 0, 0.6);

    h3 {
      color: #fbfcfc;
      font-family: ${primaryFont};
      font-size: 56.9px;
      font-weight: 700;
      letter-spacing: -1.14px;
      line-height: 56.9px;
      text-align: center;

      margin-bottom: 50px;

      @media (max-width: 700px) {
        font-size: 28.9px;

        margin-bottom: 20px;
      }
    }

    & .single-input-field {
      position: relative;
      width: 256px;
      height: 48px;
      border-radius: 90px;
      overflow: hidden;
      border: 2px solid;
      border-color: #353945;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 80px;

      .enter-your-email {
        position: absolute;
        width: 182px;
        height: 20px;
        top: 12px;
        left: 16px;
        color: #777e90;

        font-family: ${secondaryFont};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .button-secondary {
        position: absolute;
        width: 32px;
        height: 32px;
        top: 6px;
        left: 216px;
      }

      @media (max-width: 700px) {
        margin-bottom: 0px;
      }
    }

    & .social-container {
      display: none;
      margin-top: 32px;
      margin-bottom: 33px;

      img {
        height: 21px;
      }
      @media (max-width: 700px) {
        display: flex;
        justify-content: center;
      }
    }

    & .text-wrapper {
      color: #777e90;
      font-family: ${secondaryFont};
      font-size: 10px;
      font-weight: 400;
      left: 0;
      letter-spacing: 0;
      line-height: 21.3px;
      // position: fixed;
      text-align: center;
    }
  }

  & .flex-container {
    display: flex;
    justify-content: space-between;
  }

  & .flex-container-2 {
    display: flex;
    align-items: center;
  }
`;

export default HomeStyle;
