export const backgroundColor = {
  100: '#ffffff',
  200: '#2b2d3e',
  300: '#ccd4e7',
  400: '#f6faff',
  500: '#131f37'
};

export const primaryColor = {
  100: '#131f37',
  200: '#2b568f',
  300: '#E6E8EC'
};

export const textColor = {
  100: '#ffffff',
  200: '#14120c',
  300: '#535353',
  400: '#FCFCFD',
  500: '#777E90'
};
